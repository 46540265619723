import Http from "./Http";

class AdminsService extends Http {
    static $displayName = "AdminsService";

    async getAdminsRoles(id, params) {
        return await this.get(`/admins-management/organisations/${id}/roles`, { params });
    }

    async getRole(id, roleId) {
        return await this.get(`/admins-management/organisations/${id}/roles/${roleId}/permissions`);
    }

    async createRole(id, data) {
        return await this.post(`/admins-management/organisations/${id}/roles`, data);
    }

    async editRole(id, roleId, data) {
        return await this.put(`/admins-management/organisations/${id}/roles/${roleId}/permissions`, data);
    }

    async deleteRole(id, roleId) {
        return await this.delete(`/admins-management/organisations/${id}/roles/${roleId}`);
    }

    async getAdmins(id, params) {
        return await this.get(`/admins-management/organisations/${id}/admins`, { params });
    }

    async deleteAdmin(id, userId) {
        return await this.delete(`/admins-management/organisations/${id}/admins/${userId}`);
    }
}

export default AdminsService;
