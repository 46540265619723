import VerticalLayout from "../../base/components/VerticalLayout";
import { Route, Routes, Navigate } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ErrorBoundary from "../../base/components/ErrorBoundary";
import Biomarkers from "./Biomarkers";
import FoodIntolerance from "./FoodIntolerance"
import Dashboard from "./Dashboard";
import Recommendations from "./Recommendations";
import { APP_GROUP_LINKS, APP_GROUP_ROUTES } from "./config";
import { BIOMARKERS_GROUP_ROUTES } from "./Biomarkers/config";
import CustomerManagement from "./CustomerManagement";
import CustomerResults from "./CustomerResults";
import ContentPlayground from "./ContentPlayground";
import FoodAndDiet from "./FoodAndDiet";
import UserSegments from "./UserSegments";
import Fitness from "./Fitness";
import Mealplan from "./Mealplan";
import UICustomisation from "./UICustomisation";
import HealthProgram from "./HealthProgram";
import Organisations from "./Organisations";
import { useService } from "../../base/hooks/useService";
import TemplateService from "../../services/TemplateService";
import { useTemplates, useUpdateTemplates } from "../../base/context/templates";
import { SIDEBAR_ITEMS_TYPE } from "../../base/components/VerticalLayout/Sidebar";
import Content from "./Content";
import { USER_ROLES } from "../../base/constants/shared";
import { KEY_USER } from "../../base/constants/storage";
import StorageService from "../../services/StorageService";
import MobileAppCustomisation from "./MobileAppCustomisation";
import Admins from "./Admins";


const AppRoutes = () => {
  /**
   * @type {TemplateService}
   */
  const templateService = useService(TemplateService);
  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);

  const [templates, updateTemplates] = useState([]);

  const { shouldUpdate } = useTemplates();
  const updateTemplatesOnSidebar = useUpdateTemplates()
  const userData = storage.get(KEY_USER, {})

  const schema = useMemo(() => [
    {
      label: "SIDEBAR.DASHBOARD",
      linkTo: "/app/dashboard",
      icon: "dashboard"
    },
    {
      type: SIDEBAR_ITEMS_TYPE.DIVIDER
    },
    {
      type: SIDEBAR_ITEMS_TYPE.TITLE,
      label: "SIDEBAR.PLATFORM",
      allowedRoles: [USER_ROLES.admin, USER_ROLES.superAdmin, USER_ROLES.practitioner]
    },
    {
      label: "SIDEBAR.BIOMARKERS",
      linkTo: "/app/biomarkers",
      icon: "biomarkers"
    },
    {
      label: "SIDEBAR.FOOD_INTOLERANCE",
      linkTo: "/app/food-intolerance",
      icon: "foodIntolerance"
    },
    {
      label: "SIDEBAR.RECOMMENDATIONS",
      linkTo: "/app/recommendations",
      icon: "recommendations"
    },
    {
      label: "SIDEBAR.CUSTOMER_MANAGEMENT",
      linkTo: "/app/customer-management",
      icon: "user",
      allowedRoles: [USER_ROLES.admin, USER_ROLES.superAdmin, USER_ROLES.practitioner]
    },
    {
      label: "SIDEBAR.CUSTOMER_RESULTS",
      linkTo: APP_GROUP_LINKS.CUSTOMER_RESULTS,
      icon: "customerResults",
      templates,
    },
    {
      type: SIDEBAR_ITEMS_TYPE.DIVIDER
    },
    {
      type: SIDEBAR_ITEMS_TYPE.TITLE,
      label: "SIDEBAR.MOBILE_APP"
    },
    {
      label: "SIDEBAR.HEALTH_PROGRAM",
      linkTo: "/app/health-program/",
      icon: "healthProgram",
      children: [
        {
          label: "SIDEBAR.NUTRITION",
          linkTo: "/app/health-program/nutrition",
          icon: "nutrition",
        },
        {
          label: "SIDEBAR.FITNESS",
          linkTo: "/app/health-program/fitness",
          icon: "fitnessProgram",
        },
        {
          label: "SIDEBAR.WELLNESS",
          linkTo: "/app/health-program/wellness",
          icon: "wellness",
        },
      ],
    },
    {
      label: "SIDEBAR.CONTENT",
      linkTo: "/app/contents",
      icon: "content",
    },
    {
      label: "SIDEBAR.FOOD_AND_RECIPES",
      linkTo: "/app/food-and-diet",
      icon: "foodAndRecipes",
    },
    {
      label: "SIDEBAR.MEALPLAN",
      linkTo: "/app/mealplan",
      icon: "foodIntolerance",
    },
    {
      label: "SIDEBAR.USER_SEGMENTS",
      linkTo: "/app/user-segments",
      icon: "userSegmentsIcon",
    },
    {
      label: "SIDEBAR.FITNESS",
      linkTo: "/app/fitness",
      icon: "fitness",
    },
    {
      label: "SIDEBAR.APP_CUSTOMIZATION",
      linkTo: "/app/mobile-app-customisation",
      icon: "uiCustomisation",
    },
    {
      type: SIDEBAR_ITEMS_TYPE.DIVIDER
    },
    {
      type: SIDEBAR_ITEMS_TYPE.TITLE,
      label: "SIDEBAR.SETTINGS"
    },
    {
      label: "SIDEBAR.UI_CUSTOMISATION",
      linkTo: "/app/ui-customisation/",
      icon: "uiCustomisation",
      children: [
        {
          label: "SIDEBAR.GENERAL",
          linkTo: "/app/ui-customisation/general",
          icon: "general",
        },
        {
          label: "SIDEBAR.UI_SETTINGS",
          linkTo: "/app/ui-customisation/ui-settings",
          icon: "uiCustomisation",
        },
        {
          label: "SIDEBAR.EMAILS",
          linkTo: "/app/ui-customisation/emails",
          icon: "emails",
        },
        {
          label: "SIDEBAR.THIRD_PARTY_SERVICES",
          linkTo: "/app/ui-customisation/third-party-services",
          icon: "thirdPartyServices",
        }
      ],
    },
    {
      label: "SIDEBAR.ORGANISATIONS",
      linkTo: "/app/organisations/",
      icon: "organisation",
    },
    {
      label: "SIDEBAR.ADMINS",
      linkTo: "/app/admins/",
      icon: "admin",
    },

  ], [templates]);

  const getTemplates = useCallback(() => {
    templateService.getTemplateList({
      limit: 100,
      offset: 0,
      isFavourite: true,
    }).then(({ data }) => {
      updateTemplates(data)
    })
  }, [templateService]);

  useEffect(() => {
    getTemplates();
  }, [getTemplates]);

  useEffect(() => {
    if(!shouldUpdate) return;
    updateTemplatesOnSidebar({ shouldUpdate: false })
    getTemplates()
  }, [shouldUpdate]);

  return (
    <VerticalLayout schema={schema}>
      <ErrorBoundary>
        <Routes>
          <Route path={APP_GROUP_ROUTES.DASHBOARD} element={<Dashboard/>}/>
          <Route path={BIOMARKERS_GROUP_ROUTES.BASE} element={<Biomarkers/>}/>
          <Route path={APP_GROUP_ROUTES.FOOD_INTOLERANCE} element={<FoodIntolerance/>}/>
          <Route path={APP_GROUP_ROUTES.RECOMMENDATIONS} element={<Recommendations/>}/>
          <Route path={APP_GROUP_ROUTES.CUSTOMER_MANAGEMENT} element={<CustomerManagement/>}/>
          <Route path={APP_GROUP_ROUTES.CUSTOMER_RESULTS} element={<CustomerResults/>}/>
          <Route path={APP_GROUP_ROUTES.FOOD_AND_DIET} element={<FoodAndDiet/>}/>
          <Route path={APP_GROUP_ROUTES.CONTENT_PLAYGROUND} element={<ContentPlayground />}/>
          <Route path={APP_GROUP_ROUTES.USER_SEGMENTS} element={<UserSegments />}/>
          <Route path={APP_GROUP_ROUTES.FITNESS} element={<Fitness />}/>
          <Route path={APP_GROUP_ROUTES.MEALPLAN} element={<Mealplan />}/>
          <Route path={APP_GROUP_ROUTES.MOBILE_APP_CUSTOMISATION} element={<MobileAppCustomisation />}/>
          <Route path={APP_GROUP_ROUTES.UI_CUSTOMISATION} element={<UICustomisation />}/>
          <Route path={APP_GROUP_ROUTES.HEALTH_PROGRAM} element={<HealthProgram />}/>
          <Route path={APP_GROUP_ROUTES.ORGANISATIONS} element={<Organisations />}/>
          <Route path={APP_GROUP_ROUTES.CONTENTS} element={<Content />}/>
          <Route path={APP_GROUP_ROUTES.ADMINS} element={<Admins />}/>
          {userData.role === USER_ROLES.practitioner ?
            <Route path="/" element={<Navigate to={APP_GROUP_LINKS.CUSTOMER_MANAGEMENT}/>} />
            :
            <Route path="/" element={<Navigate to={APP_GROUP_LINKS.DASHBOARD}/>} />
          }
        </Routes>
      </ErrorBoundary>
    </VerticalLayout>
  );
};

export default AppRoutes;
