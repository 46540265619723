import Http from "./Http";

class PartnersService extends Http {
    static $displayName = "PartnersService";

    async registerPartner(data) {
        return await this.post(`/partners`, data);
    }

    async specialisationsList() {
        return await this.get(`/partners/specialisations`);
    }

    async addPractitionerProfessionalCredentials(data) {
        return await this.post(`/partners/me/professional-credentials`, data);
    }

    async getPractitionerProfessionalCredentials() {
        return await this.get(`/partners/me/professional-credentials`);
    }
}

export default PartnersService;