import React from "react";
import AuthPartnerLayout from "../../../../components/AuthPartnerLayout";

const Step2 = () => {
    return(
        <AuthPartnerLayout>
            <div className="w-100 d-flex align-items-center justify-content-center">
                <div className="partner-step-layout">
                    <div>
                        <h1 className="partner-step-title">
                            <span className="me-3">2
                                <span className="partner-text-grey font-size-16 me-2">/2</span>
                            </span>
                            Tell us about your practice
                        </h1>
                    </div>
                    <p className="partner-text-grey font-size-18 mt-2 mb-0">
                        Let us know how clients will find you (optional).
                    </p>
                    <div className="mt-4-5">
                    </div>
                </div>
            </div>
        </AuthPartnerLayout>
    )
}

export default Step2;